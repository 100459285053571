<template>
  <div
      class="adminUsersTable"
  >
    <table>
      <tr>
        <th>{{translation("global_input-firstname-label")}}</th>
        <th>{{translation("global_input-lastname-label")}}</th>
        <th>{{translation("global_input-email-label")}}</th>
        <th>{{translation("global_input-phone-label")}}</th>
        <th>{{translation("admin_table-registration_date")}}</th>
        <th>{{translation("admin_table-courses_done")}}</th>
        <th>{{translation("admin_table-contacted_date")}}</th>
      </tr>
      <tr v-for="user in this.users" :key="user.id">
        <td>{{ user.firstname }}</td>
        <td>{{ user.lastname }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.phone }}</td>
        <td>{{ dateFormat(user.date_created) }}</td>
        <td>{{ user.courses_done }}</td>

        <td class="checkbox-date">
          <input v-if="user.contacted" @change="saveDate(user)" type="date" v-model="user.contacted_date"/>
        </td>
        <td class="checkbox">
          <BaseCheckbox
            :uuid="user.id.toString()"
            v-model="user.contacted"
            @changeValue="this.save"
        >

        </BaseCheckbox>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>

import BaseCheckbox from "@/components/BaseCheckbox";
import {userService} from "@/services/user.service";
import formatMixins from "@/mixins/formatMixins";
import codeBooksMixin from "@/mixins/codeBooksMixin";

export default {
  props: {
    users: []
  },
  components: {
    BaseCheckbox
  },
  data() {
    return {

    }
  },
  mixins: [codeBooksMixin, formatMixins],
  methods: {
    save(data){
      let userId = parseInt(data.id);
      const index = this.users.findIndex(row => {
        return row.id === userId;
      })
      let userData = this.users[index];
      console.log(JSON.stringify(userData));
      userService.updateUser(userId,
          userData.firstname,
          userData.lastname,
          userData.email,
          userData.phone,
          userData.newsletter_subscribed,
          userData.university_student,
          userData.contacted)
    },
    saveDate(userData){
      userService.updateUser(userData.id,
          userData.firstname,
          userData.lastname,
          userData.email,
          userData.phone,
          userData.newsletter_subscribed,
          userData.university_student,
          userData.contacted,
          userData.contacted_date)
    }
  }
}
</script>