<template>
  <NavigationList
      class="adminNavigation"
      :label="'Admin'"
  >
    <NavigationListItem
        :url="'/admin'"
        :page="'admin'"
    >
      <img
          src="../assets/images/icons/dashboard.svg"
          alt=""
          class="icon"
          width="18"
          height="18"
          loading="lazy"
      >
      Uživatelé
    </NavigationListItem>
    <NavigationListItem
        :url="'/admin-trainings'"
        :page="'admin-trainings'"
    >
      <img
          src="../assets/images/icons/edit.svg"
          alt=""
          class="icon"
          width="18"
          height="20"
          loading="lazy"
      >
      Školení
    </NavigationListItem>
  </NavigationList>
</template>

<script>
import NavigationList from "@/components/NavigationList";
import NavigationListItem from "@/components/NavigationListItem";
import CodeBooksMixin from "@/mixins/codeBooksMixin";
import {mapState} from 'vuex'

export default {
  components: {NavigationList, NavigationListItem},
  mixins: [CodeBooksMixin],
  computed: {
    ...mapState("page", {
      currentPage: (state) => state.currentPage,
    })
  }
}
</script>